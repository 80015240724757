<template>
	<section id="section-2" class="section-content">
		<h2 class="intro-title intro-title-2" v-html="doc.s2.title_2"></h2>

		<p class="intro-item intro-item-3"><span @click="gotochat()" class="cursor-point">GBWhatsApp</span> APK provides customization features and a plethora of other options. Refer to the list below, and we are confident that you will be persuaded to acquire this modification for your device.</p>

		<div class="advertisment-item">
			<!-- <img :src="doc.s2.adimg_1" alt="" /> -->
			<picture>
				<source :srcset="doc.s2.adimg_1_new_webp" type="image/webp" />
				<img :src="doc.s2.adimg_1_new" alt="Screen" />
			</picture>
		</div>

		<div class="intro-li-row">
			<ul class="list-icons">
				<li v-for="(data, i) in doc.s2new.ul" :key="i">
					<i class="fa"></i>
					<strong v-html="data.head"></strong>
					<div class="list-detail" v-html="data.content"></div>
					<div class="list-img" v-if="data.img">
						<picture>
							<source :srcset="data.img_webp" type="image/webp" />
							<img :src="data.img" width="auto" height="auto" alt="ul-img" />
						</picture>
					</div>
				</li>
			</ul>
		</div>

		<h2 class="intro-title intro-title-3" v-html="doc.s2.title_3"></h2>

		<p class="intro-item intro-item-4">You won't be able to locate the <span @click="gotoorg()" class="cursor-point">GBWhatsApp MOD</span> version on Google Play, therefore, If you want to know the way of GB WhatsApp download, then you need to find it from the website and manually install it. If you're not familiar with the process for GB WhatsApp apk download on your Android device, refer to this installation guide.</p>

		<p class="intro-item intro-item-5" v-html="doc.s2.intro_5_new"></p>

		<div class="advertisment-item adv-2">
			<!-- <img :src="doc.s2.adimg_2" alt="" /> -->
			<picture>
				<source :srcset="doc.s2.adimg_2_webp" type="image/webp" />
				<img :src="doc.s2.adimg_2" alt="Screen" />
			</picture>
		</div>

		<p class="intro-item intro-item-5">However，if somebody download GB WhatApp old version and want to update it into the latest version, please follow the steps too. </p>

		<ul class="intro-item intro-item-5 content-li">
			<li>
				First, make sure you have downloaded the GB WhatsApp apk on your phone.
				Then you will need to go to our official GB WhatsApp apk download website to obtain the latest version installation file. Actually, You will find our site always keeping up with the latest GB WhatsApp version, usually on the homepage or "Download" page.

			</li>
			<li>
				After downloading, you need to open the GB WhatsApp and go to its settings interface. In there, you will find an option for "Version Info". you can check the current version and see if gb whatsapp update is needed. If an update is required, you simply need to click on the "GB WhatsApp Update" button and wait for the installation file to finish downloading. after that, you will be prompted to install the latest GB WhatsApp version. Click the "Install" button to start the installation update process. This process may take several minutes, depending on your device and network connection speed.
			</li>
			<li>
				Now, you will be able to use the latest version of GB WhatsApp with updated features and improvements.

			</li>
		</ul>
		<p class="intro-item intro-item-5">
			Remember to always keep your GB WhatsApp updated to ensure the best performance and security.
		</p>
	</section>
</template>

<script>
export default {
	name: "s2",
	props: {
		doc: Object,
	},
	methods: {
		gotochat() {
			window.location.href = "https://www.whatsplus.site/";
		},
		gotoorg() {
			window.location.href = "https://www.whatspro.org/";
		},
	},
};
</script>
